import $ from "jquery";
// import { browserName } from "react-device-detect";
import { NotificationManager } from "react-notifications";
import axios from "axios";

// const url = "http://localhost:5009/api";
const url = "https://api.bithaven.exchange/api";
export const WebsiteURL = "https://bithaven.exchange";
export const adminurl = "https://admin-api.bithaven.exchange";
const orderurl = "https://order.bithaven.exchange/api";
// const orderurl = "http://localhost:5000/api";

// const signal = new AbortController();

export function N_userRegister(email, mobile_number, password, referral) {
  return fetch(`${url}/register-user`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      email: email,
      mobile_number: mobile_number,
      password: password,
      parent_ref_code: referral,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_verifyOTP(otp, session_id) {
  return fetch(`${url}/varifie/email`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ otp: otp, user_id: session_id }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_userLogin(email, mobile, password, otp) {
  // console.log("N_login: ", email, password);
  return fetch(`${url}/login-new`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      email: email,
      password: password,
      mobile: mobile,
      otp: otp,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_resendOTP(email, mobile_number) {
  return fetch(`${url}/resend-otp`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ email, mobile_number }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_forgetPassward(email) {
  return fetch(`${url}/forget-password`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      email: email,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_verifyForgetOTP(otp, email) {
  return fetch(`${url}/varifie/forget-password`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ otp: otp, email: email }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}
export function N_updateName(token, name) {
  return fetch(`${url}/update-name`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      name,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_profileData(
  token,
  type,
  id_no,
  pan_no,
  first_name,
  last_name,
  country
) {
  return fetch(`${url}/kyc/update-profile`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      type,
      id_no,
      pan_no,
      first_name,
      last_name,
      country,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_sendUpdateEmail(token, email, action) {
  return fetch(`${url}/send-update-email`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ email, action }),
  })
    .then((d) => {
      return d.json()
    })
    .catch((e) => {
      return e
    });
}

export function N_verifyUpdateEmail(token, email, otp, action) {
  return fetch(`${url}/verify-update-email`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ email, otp, action }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}
export function N_updatePassword(token, password, confirm_password) {
  return fetch(`${url}/set-password`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      confirm_password: confirm_password,
      password: password,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_freezeAccount(token, action) {
  return fetch(`${url}/freeze-acount`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ action }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_resetPassword(
  token,
  last_password,
  password,
  confirm_password
) {
  return fetch(`${url}/reset-password`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      last_password,
      password,
      confirm_password,
    }),
  }).then((d) => d.json());
}

export function N_changePassword(
  token,
  password,
  confirm_password
) {
  return fetch(`${url}/otp-send-password`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      password,
      confirm_password,
    }),
  }).then((d) => d.json());
}

export function N_verifyPassword(
  token,
  otp
) {
  return fetch(`${url}/verify-otp-password`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
     otp
    }),
  }).then((d) => d.json());
}
export function N_getVHL(symbol) {
  return fetch(`${orderurl}/chart/getVHL`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ symbol: symbol }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}
export function N_RemoteStatus(currency_type, compare_currency) {
  return fetch(`${url}/get-remote-status`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ currency_type, compare_currency }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}
export function N_RemoteStatusAll() {
  return fetch(`${url}/get-all-remote-status`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_getWatchlist(token, currency_type, compare_currency) {
  return fetch(`${url}/fav-symbol`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`
    },
    body:JSON.stringify({currency_type, compare_currency})
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_futureBuyOffer(
  raw_price,
  stop_loss,
  stop_profit,
  leverage,
  volume,
  currency_type,
  compare_currency,
  token,
  cprice,
  type = "buy",
  action = "limit"
) {
  return fetch(`${orderurl}/create-future-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      currency_type: currency_type,
      volume: volume,
      compare_currency: compare_currency,
      raw_price: raw_price,
      stop_loss: stop_loss,
      stop_profit: stop_profit,
      leverage: leverage,
      type: type,
      cprice: cprice,
      action:action
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}
export function N_futureSellOffer(
  raw_price,
  stop_loss,
  stop_profit,
  leverage,
  volume,
  currency_type,
  compare_currency,
  token,
  cprice,
  type = "sell",
  action = "limit"
) {
  return fetch(`${orderurl}/create-future-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      currency_type: currency_type,
      volume: volume,
      compare_currency: compare_currency,
      raw_price: raw_price,
      stop_loss: stop_loss,
      stop_profit: stop_profit,
      leverage: leverage,
      type: type,
      cprice: cprice,
      action:action
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}
export function N_createBuyOffer(
  raw_price,
  stop_price,
  volume,
  currency_type,
  compare_currency,
  token,
  cprice,
  type = "buy",
  action = "limit"
) {
  return fetch(`${orderurl}/create-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      currency_type: currency_type,
      volume: volume,
      compare_currency: compare_currency,
      raw_price: raw_price,
      stop_price:stop_price,
      type: type,
      cprice: cprice,
      action:action
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_createp2pSellOrder(
  token,
  raw_price,
  volume,
  currency_type,
  compare_currency,
  type,
  payment_method
) {
  return fetch(`${url}/sell-p2p-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      raw_price,
      volume,
      currency_type,
      compare_currency,
      type,
      payment_method
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}
export function N_p2pSellOrder(token, type, coin, amount, payment_method) {
  return fetch(`${url}/p2p-order-list`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      type: type,
      coin: coin,
      amount:amount,
      payment_method
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_setChat(token, order_id, message) {
  return fetch(`${url}/set-chat`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      order_id, message
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_getChat(token, order_id) {
  return fetch(`${url}/get-chat`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      order_id
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}


export function N_p2pMyOrder(token, type, coin, status, start_date, end_date) {
  return fetch(`${url}/p2p-myorder-list`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      type,
      coin,
      status, start_date, end_date
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_p2pInitiateOrder(token, order_id, amount) {
  return fetch(`${url}/initiate-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      order_id, amount
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_p2pConfirmOrder(token, order_id, type, order_idd) {
  return fetch(`${url}/confirm-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      order_id, type, order_idd
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_p2pOrderAction(token, order_id, type) {
  return fetch(`${url}/p2p-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      order_id,
      type: type,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_p2pOrderAppeal(token, order_id) {
  return fetch(`${url}/p2p-appeal`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      order_id
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_getp2pOrder(token, order_id, type) {
  return fetch(`${url}/get-p2p-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      order_id,
      type
      
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_p2pCancelOrder(token, order_id) {
  return fetch(`${url}/p2p-cancel-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      order_id,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_p2pCancelIntiateOrder(token, order_id) {
  return fetch(`${url}/p2p-cancel-init-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      order_id,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_createSellOffer(
  raw_price,
  stop_price,
  volume,
  currency_type,
  compare_currency,
  token,
  cprice,
  type = "sell",
  action = "limit"
) {
  return fetch(`${orderurl}/create-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      currency_type: currency_type,
      volume: volume,
      compare_currency: compare_currency,
      raw_price: raw_price,
      stop_price:stop_price,
      type: type,
      cprice: cprice,
      action:action
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}
export function N_executeOrder(order_id, token, type) {
  // console.log("--- Execute Order ---");
  return fetch(`${orderurl}/execute-orders`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      order_id: order_id,
      type: type,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_executeFutureOrder(order_id, token, type) {
  // console.log("--- Execute Order ---");
  return fetch(`${orderurl}/execute-future-orders`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      order_id: order_id,
      type: type,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}


export function N_userInfoSubmit(body, token) {
  return fetch(`${url}/kyc/set-personal-info`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      kyc_type: body.type_of_kyc,
      first_name: body.fname,
      middle_name: body.mname,
      last_name: body.lname,
      date_of_birth: new Date(body.dob).getTime(),
      address: body.address,
      country: body.country,
      state: body.state,
      city: body.city,
      pincode: body.pincode,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}
export function N_userEditSubmit(body) {
  return fetch(`${url}/updateprofile`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(body),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_setKyc(event, token) {
  const formControl = event.target?.parentElement;
  let body = {};
  try {
    formControl.querySelector(".spinner-border").style.display = "block";
    const typeInput = formControl.querySelector("input");
    if (typeInput.type === "text") {
      const val = formControl.querySelector("input").value;
      if (typeInput.id === "user_adhar") {
        body.data = {
          value: val,
          type: "Adhar",
        };
        body.title = "docn";
      } else if (typeInput.id === "user_dl") {
        body.data = {
          value: val,
          type: "Driving",
        };
        body.title = "docn";
      } else if (typeInput.id === "user_passport") {
        body.data = {
          value: val,
          type: "Passport",
        };
        body.title = "docn";
      } else if (typeInput.id === "user_pancard") {
        body.data = val;
        body.title = "pann";
      }
    }
  } catch (e) {
    console.log("setkyc: ", e);
  }

  try {
    fetch(`${url}/kyc/update-documents`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "cache-control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((d) => d.json())
      .then((res) => {
        if (res && res.status === 200) {
          // console.log("success: ", res);
          $("#" + event.target.id).text("Resend");
        }
        if (res && res.status === 400) {
          console.log("error: ", res);
          NotificationManager.error(res.message);
          $("#" + event.target.id).text("Resend");
        }
        formControl.querySelector(".spinner-border").style.display = "none";
      })
      .catch((e) => {
        console.log(e);
      });
  } catch (error) {
    console.error("An unexpected error happened:", error);
  }
}

export function N_uploadID(event, file, title, token, adhar_no="") {
  event.preventDefault();
  const formData = new FormData();
  // The third parameter is required for server
  formData.append("file", file, file.name);
  formData.append("title", title);
  formData.append("data", title);
  if(adhar_no) {
    formData.append("aadhar_no", adhar_no);
  }
  let auth_token = `Bearer ${token}`;
  axios
    .post(`${url}/upload-kyc`, formData, {
      headers: {
        Authorization: auth_token,
      },
    })
    .then((res) => {
      if (res && res.status === 200) {
        if (title === "docb") {
          setTimeout(()=>{
            document.location.reload();
          },1000)
        }
      }
      if (res && res.status === 400) {
        return res.data.message;
      }
    })
    .catch((e) => {
      console.log(e);
    });
}

export function N_uploadBankDoc(event, file, title, token) {
  event.preventDefault();

  const formData = new FormData();

  formData.append("file", file, file.name);
  formData.append("title", title);
  formData.append("data", title);

  const auth_token = `Bearer ${token}`;

  axios
    .post(`${url}/banking/upload-bank-document`, formData, {
      headers: {
        Authorization: auth_token,
      },
    })
    .then((res) => {
      if (res && res.status === 200) {
        console.log("res", res);
      }

      if (res && res.status === 400) {
        console.log("res error", res);
      }
    })
    .catch((e) => console.log(e));
}

export function N_test(event, file, title, user_id) {
  event.preventDefault();
  const formControl = event.target?.parentElement;
  formControl.querySelector(".spinner-border").style.display = "block";
  const formData = new FormData();
  console.log(file);
  // The third parameter is required for server
  formData.append("file", file, file.name);
  formData.append("title", title);
  formData.append("user_id", user_id);
  formData.append("data", title);
  console.log("chalform0: ", formData);
  axios
    .post(`${url}/kyc/update-documents`, formData)
    .then((res) => {
      if (res && res.status === 200) {
        console.log("chalform: ", formData);
        $("#" + event.target.id).text("Resend");
      }
      if (res && res.status === 400) {
        NotificationManager.error(res.msg);
        $("#" + event.target.id).text("Resend");
      }
      formControl.querySelector(".spinner-border").style.display = "none";
    })
    .catch((e) => {
      console.log(e);
    });
}

export function N_ScreenShot(event, file, price, token, req_no) {
  event.preventDefault();
  const formControl = event.target?.parentElement;
  const formData = new FormData();
  // The third parameter is required for server
  if (file && price && token) {
    formControl.querySelector(".spinner-border").style.display = "block";
    formData.append("file", file, file.name);
    formData.append("price", price);
    formData.append("req_no", req_no);
    let auth_token = `Bearer ${token}`;

    axios
      .post(`${url}/upload-screenshot`, formData, {
        headers: {
          Authorization: auth_token,
        },
      })
      .then((res) => {
        const data = res.data;
        if (res.status === 200 && data.status === 200) {
          NotificationManager.success(data.message);
        } else {
          NotificationManager.error(data.message);
        }
        formControl.querySelector(".spinner-border").style.display = "none";
      })
      .catch((e) => {
        NotificationManager.error("Something went Wrong!!");
      });
  } else {
    NotificationManager.error("Provide all Data");
  }
}

export function N_Screenp2pShot(
  event,
  file,
  transection_id,
  order_token,
  token
) {
  event.preventDefault();
  const formControl = event.target?.parentElement;
  const formData = new FormData();
  // The third parameter is required for server
  if (file && transection_id && order_token && token) {
    formControl.querySelector(".spinner-border").style.display = "block";
    formData.append("file", file, file.name);
    formData.append("transection_id", transection_id);
    formData.append("order_token", order_token);
    let auth_token = `Bearer ${token}`;

    axios
      .post(`${url}/upload-payment-screenshot`, formData, {
        headers: {
          Authorization: auth_token,
        },
      })
      .then((res) => {
        const data = res.data;
        if (res.status === 200 && data.status === 200) {
          NotificationManager.success(data.message);
        } else {
          NotificationManager.error(data.message);
        }
        formControl.querySelector(".spinner-border").style.display = "none";
      })
      .catch((e) => {
        NotificationManager.error("Something went Wrong!!");
      });
  } else {
    NotificationManager.error("Provide all Data");
  }
}

export function N_checkKYCStatus(token) {
  return fetch(`${url}/kyc/check-status`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({}),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getBlockchainData(blockchain, token, symbol) {
  return fetch(`${url}/get-blockchain-data`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      blockchain,
      symbol,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getBlockchain() {
  return fetch(`${url}/get-blockchain`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_GetAllSymbol() {
  return fetch(`${url}/getallsymbol`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_ischeckKycSubmited(token, data) {
  return fetch(`${url}/kyc/update-documents`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      title: "kycstatus",
      data: data,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_get_wallet(token) {
  return fetch(`${url}/get-wallets-new`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_get_walletp2p(token) {
  return fetch(`${url}/get-wallets-p2p`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_get_walletfuture(token) {
  return fetch(`${url}/get-wallets-future`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_get_future_order(token, action) {
  return fetch(`${url}/get-future-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body:JSON.stringify({ action }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_transferAmonut(type, wallet_type, token, volume, action) {
  return fetch(`${url}/transfer-fund`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ type, wallet_type, volume, action }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_createAddress(token, symbol) {
  return fetch(`${url}/create-wallets-user`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      symbol: symbol,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_getSupportedCurrency(token) {
  return fetch(`${url}/getpairedCurrency`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_tradeOrder(token, action, page, limit) {
  return fetch(`${orderurl}/order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ action, page, limit }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_spotOrder(token, pair, side, type, action, page, limit) {
  return fetch(`${orderurl}/order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ pair, side, type, action, page, limit }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}
export function N_futureOrder(token, pair, side, type, action, page, limit) {
  return fetch(`${url}/futureorder`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ pair, side, type, action, page, limit }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_paymentMethod(token) {
  return fetch(`${url}/banking/payment-method`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getPaymentMethodUser(token, order_id, payment_method) {
  return fetch(`${url}/banking/get-payment-method`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body:JSON.stringify({order_id, payment_method})
  })
    .then((d) => d.json())
    .catch((e) => e);
}


export function N_getUserBankDetails(token) {
  return fetch(`${url}/banking/get-banking-status`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getUserUPIDetails(token) {
  return fetch(`${url}/banking/get-upi-status`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_addBinficiary(
  account_number,
  bank_name,
  ifsc_code,
  account_type,
  token
) {
  return fetch(`${url}/banking/set-banking-info`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      bank_name: bank_name,
      account_type: account_type,
      account_number: account_number,
      ifsc_code: ifsc_code,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_addUPI(
  upi_id,
  token
) {
  return fetch(`${url}/banking/set-banking-UPI`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      upi_id
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_OrdersHistory(token) {
  return fetch(`${url}/order-history`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_pendingOrder(token, currency_type, compare_currency) {
  return fetch(`${url}/pending-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body:JSON.stringify({
      currency_type, 
      compare_currency
    }),
    
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_completeOrder(token, currency_type, compare_currency) {
  return fetch(`${url}/complete-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body:JSON.stringify({
      currency_type, 
      compare_currency
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_setGoogleAuth(token) {
  return fetch(`${url}/set-auth-google`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({}),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_setGoogleAuthOtp(token, state) {
  return fetch(`${url}/set-auth-google-otp`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      status: state,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_setGoogleAuthOTP(token, state, otp, action) {
  return fetch(`${url}/set-auth-google-otp`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      status: state,
      otp,
      action,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_get_settings(token) {
  return fetch(`${url}/get-auth-google-setting`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_getUserProfile(token) {
  return fetch(`${url}/user/get-profile-info`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getProfile(token) {
  return fetch(`${url}/get-profile-info`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({}),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getLeaser(token) {
  return fetch(`${url}/user/get-leaser`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({}),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getTradeData(token) {
  return fetch(`${url}/user/get-trade-data`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({}),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_sendOTPMobile(token, mobile_number) {
  return fetch(`${url}/send-mobile-varification-otp`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      mobile_no: mobile_number,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_sendOTPMobileEmail(email, mobile_number) {
  return fetch(`${url}/send-mobile-varification-otp-email`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      email: email,
      mobile_no: mobile_number,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_sendOTP(email, mobile, action) {
  return fetch(`${url}/sendOTP`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ email, mobile, action }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_verifyOTPNew(email, mobile, otp) {
  return fetch(`${url}/verifyOTP`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ email, mobile, otp }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_verifyOTPMobile(token, otp) {
  return fetch(`${url}/varifie/mobile`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      otp: otp,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}
export function N_verifyOTPMobileLogin(email, otp) {
  return fetch(`${url}/varifie/mobile-login`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      email: email,
      otp: otp,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_checkMobileStatus(token) {
  return fetch(`${url}/check_user_status`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getGoogleAuth(email, mobile, password) {
  return fetch(`${url}/get-auth-google`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      email: email,
      mobile: mobile,
      password: password,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_getRefferalData(token) {
  return fetch(`${url}/user/get-referals`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((d) => d.json())
    .catch((e) => e);
}


export function N_getRewardData(token) {
  return fetch(`${url}/user/get-reward`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getLevelIncome(token) {
  return fetch(`${url}/user/get-level`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_cancleOrderById(token, order_id) {
  return fetch(`${orderurl}/cancel-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      order_id: order_id,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_cancelFutureOrderById(token, order_id, price) {
  return fetch(`${url}/cancel_future-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      order_id: order_id,
      price:price
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_closeFutureOrder(token, order_id, type, price) {
  return fetch(`${orderurl}/close-future-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      order_id: order_id,
      type:type,
      current_price:price
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_updateFutureOrder(token, id, stop_loss, stop_profit) {
  return fetch(`${orderurl}/update-future-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      id, stop_loss, stop_profit
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_DepositUpdate(token) {
  return fetch(`${url}/update-wallet`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((d) => d)
    .catch((e) => e);
}

export function N_crypto_withdraw(
  token,
  symbol,
  address,
  to_address,
  volume,
  remark
) {
  return fetch(`${url}/get-withdraw`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      fromAddress: address,
      symbol: symbol,
      toAddress: to_address,
      volume: volume,
      remark: remark,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_crypto_withdraw_New(
  token,
  symbol,
  to_address,
  volume,
  network
) {
  return fetch(`${url}/get-withdraw`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      symbol: symbol,
      toAddress: to_address,
      volume: volume,
      network: network,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_crypto_withdraw_Otp(token, otp, transection_id, wallettype) {
  return fetch(
    `${url}/varifie/mobile-Withdraw
  `,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "cache-control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        otp: otp,
        transection_id: transection_id,
        symbol: wallettype,
      }),
    }
  )
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_crypto_withdraw_Otp_Email(
  token,
  otp,
  transection_id,
  wallettype
) {
  return fetch(
    `${url}/varifie/email-Withdraw
  `,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "cache-control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        otp: otp,
        transection_id: transection_id,
        symbol: wallettype,
      }),
    }
  )
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_inr_withdraw(token, volume, symbol, remark, payment_method) {
  return fetch(`${url}/banking/inr_withdrawal`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      volume: volume,
      symbol: symbol,
      remark: remark,
      payment_method: payment_method,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_TransferP2P(token, volume, wallet_type, type) {
  return fetch(`${url}/update-p2p-fund`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      volume,
      wallet_type,
      type,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_withdraw_coin(transection_id) {
  return fetch(`${url}/success-withdrawal`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      transection_id: transection_id,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_transectionHistory(user_id) {
  return fetch(`${url}/transection_history`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({}),
  })
    .then((d) => d.json())
    .catch((e) => e);
}
export function N_depositHistory(data, token) {
  return fetch(`${url}/deposit_history`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then((d) => d.json())
    .catch((e) => e);
}
export function N_withdrawHistory(data, token) {
  return fetch(`${url}/withdraw_history`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_FiatHistory(data, token) {
  return fetch(`${url}/fiat_history`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_withdrawInrHistory(token, data) {
  return fetch(`${url}/withdraw-inr-history`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_withdraw_inr(transection_id) {
  return fetch(`${url}/success-inr-withdrawal`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      transection_id: transection_id,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getCountry(token) {
  return fetch(`${url}/kyc/get-country`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ action: "country" }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_getState(country, token) {
  return fetch(`${url}/kyc/get-country`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      action: "state",
      country_name: country,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_getCity(country, state, token) {
  return fetch(`${url}/kyc/get-country`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      action: "city",
      country_name: country,
      state_name: state,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_getBank(token) {
  return fetch(`${url}/banking/get-bank`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({}),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getWebsiteData() {
  return fetch(`${url}/get-website-data`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((d) => d.json())
    .catch((e) => e);
}
export function N_getOrderBookDepth(currency_type, compare_currency, type, price,  limit = 20) {
  return fetch(`${url}/v3/depth?limit=${limit}&currency_type=${currency_type}&compare_currency=${compare_currency=="inr"?"usdt":compare_currency}&type=${type}&price=${price}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((d) => d.json())
    .catch((e) => e);
}


export function N_getTicker(symbol, price=1) {
  return fetch(`${url}/v3/ticker?symbol=${symbol?.toUpperCase()}&price=${price}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getFutureOrderBookDepth(currency_type, compare_currency, type, price,  limit = 20) {
  return fetch(`${url}/fv3/depth?limit=${limit}&currency_type=${currency_type}&compare_currency=${compare_currency=="inr"?"usdt":compare_currency}&type=${type}&price=${price}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((d) => d.json())
    .catch((e) => e);
}


export function N_getFutureTicker(symbol, price=1) {
  return fetch(`${url}/fv3/ticker?symbol=${symbol?.toUpperCase()}&price=${price}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_setActivityReport(token) {
  return fetch(`${url}/activity-log`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      action: "set_report",
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}
export function N_getActivityReport(token) {
  return fetch(`${url}/activity-log`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      action: "get_report",
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getNotification(token) {
  return fetch(`${url}/notification`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      action: "get",
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_UserNotification(token) {
  return fetch(`${url}/get-notification`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    }
  })
    .then((res) => res.json())
    .catch((e) => e);
}
export function socialSignup(access_token, referral_code="") {
  return fetch(`${url}/signup-social`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      access_token: access_token,
      parent_ref_code: referral_code
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}


export function socialLogin(access_token) {
  return fetch(`${url}/login-social`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      access_token: access_token
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_panVerify(pan_no, annual_income, job_profesion, token) {
  return fetch(`${url}/kyc/verify-pan`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      pan_no: pan_no,
      annual_income, job_profesion,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_sendAADHAROTPMobile(aadhar_no, token) {
  return fetch(`${url}/kyc/validate-aadhar`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      aadhar_no: aadhar_no,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_verifyAADHAROTPMobile(client_id, otp, token) {
  return fetch(`${url}/kyc/verify-adhar`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      otp: otp,
      client_id: client_id
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getStake(token, symbol, days) {
  return fetch(`${url}/get-staking`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      symbol, days
    })
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_setStake(token,  wallet_type, amount, days) {
  return fetch(`${url}/set-staking`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      wallet_type,
      amount,
      days
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}
export function N_getHarvest(token, id) {
  return fetch(`${url}/harvest`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      id:id
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}
// export function N_getHarvest(token, symbol, days, harvest_amount) {
//   return fetch(`${url}/get-harvest`, {
//     method: "POST",
//     headers: {
//       "content-type": "application/json",
//       "cache-control": "no-cache",
//       "Access-Control-Allow-Origin": "*",
//       Authorization: `Bearer ${token}`,
//     },
//     body: JSON.stringify({
//       symbol, days, harvest_amount
//     }),
//   })
//     .then((d) => d.json())
//     .catch((e) => e);
// }

export function N_updateContactDetails(token, issue, querydata) {
  return fetch(`${url}/send-contact`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      issue, querydata
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getRefStaking(token) {
  return fetch(`${url}/get-ref-staking`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    }
  }).then((d) => d.json()).catch((e) => e)
}

export function N_harvestRefStaking(token, ref_userId, invest_time, harvest_amount) {
  return fetch(`${url}/get-harvest-ref-staking`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      ref_userId, invest_time, harvest_amount
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getHarvestHistory(token, page = 1) {
  const urlWithParams = new URL(`${url}/get-harvest-history`);
  urlWithParams.searchParams.append("page", page);

  return fetch(`${urlWithParams}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    }
  }).then((d) => d.json()).catch((e) => e)
}

export function N_getStakeHistory(token, page, pageSize, symbol="") {
  return fetch(`${url}/get-stake-history`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      symbol,
      page,
      pageSize
    }),
  }).then((d) => d.json()).catch((e) => e)
}

export function N_createApiKey(data, token) {
  return fetch(`${url}/create-api-key`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      api_name: data.api_name,
      user_info_access: data.user_info_access,
      sell_access: data.sell_access,
      buy_access: data.buy_access,
    }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_getCreateAPI(token) {
  return fetch(`${url}/get-api-key`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_deleteAPIkey(api_key, token) {
  return fetch(`${url}/delete-api-key`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ api_key }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}

export function N_deleteAPIkeys(delete_all, token) {
  return fetch(`${url}/delete-api-keys`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ delete_all }),
  })
    .then((d) => d.json())
    .catch((e) => e);
}
export function getAPICall(urlslug) {
  const urlaction = url + urlslug;
  return axios.get(urlaction);
}
export function postAPICall(urlslug, fomdata, order) {
  let gurl = order ? orderurl : url;
  const urlaction = gurl + "/" + urlslug;
  return axios.post(urlaction, fomdata);
}
