import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoginORSignup from "./LoginORSignup";
import { NotificationManager } from "react-notifications";
import { AiOutlineDelete } from "react-icons/ai";
import { FiDelete } from "react-icons/fi";
import "./order.css";
import Loader from "./Loader";
import { getUserBalance, getUserOrder } from "../redux/actions/coinDBAction";
import { HiMiniChartBarSquare } from "react-icons/hi2";
import {
  N_cancleOrderById,
  N_spotOrder,
} from "../redux/helpers/api_functions_new";
import { round } from "../redux/helpers/Math";
import {
  SET_USER_ORDER_CLOSE,
  SET_USER_ORDER_PENDING,
} from "../redux/constant";
import { Button, Modal } from "react-bootstrap";
import { PiFolderNotchOpenThin } from "react-icons/pi";
import { getCoinRate, getRound } from "../redux/helpers/helper_functions";
import { Link } from "react-router-dom";
export default function OrdersTab(props) {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  const { user } = useSelector((state) => state.AuthReducer);
  const {
    user_order_pending,
    user_order_close,
    currency_type,
    campare_currency,
    wallet,
    coins,
    paired_curency_price
  } = useSelector((state) => state.coinDBReducer);
  const [pendingOrder, setpendingOrder] = useState([]);
  const [closeOrder, setcloseOrder] = useState([]);
  const coin = props?.match?.params?.id?.split("-");
  const [walletsdata, setwalletDetails] = useState([]);


  function getWallets() {
    let total = 0;
    let final_data =
      wallet &&
      Object.keys(wallet).map((res, i) => {
        let wallet_type = wallet[res]?.symbol.toUpperCase();
        let rate = getCoinRate(coins, wallet_type);
        let inr_val = rate * getRound(wallet[res]?.balance);
        let inr_available =
          rate * getRound(wallet[res]?.balance - wallet[res]?.locked);
        let inr_locked = rate * getRound(wallet[res]?.locked);
        total = total + parseFloat(inr_val);
        return {
          id: wallet[res]?.id,
          deposit_limit: wallet[res]?.deposit_limit
            ? wallet[res].deposit_limit
            : 0,
          icon: wallet[res]?.icon,
          symbol: wallet_type,
          name: wallet[res]?.name,
          status: wallet[res]?.status,
          withdral_fee: wallet[res]?.withdrawal_fee,
          locked:
            Math.round(wallet[res]?.locked * 10000) / 10000 != 0
              ? Math.round(
                  Math.abs(wallet[res]?.locked ? wallet[res]?.locked : 0) *
                    10000
                ) / 10000
              : Math.round(wallet[res]?.locked * 100000000) / 100000000,
          address: wallet[res]?.wallet_address,
          balance:
            Math.round(wallet[res]?.balance * 10000) / 10000 != 0
              ? Math.round(wallet[res]?.balance * 10000) / 10000
              : Math.round(wallet[res]?.balance * 100000000) / 100000000,
          p2pbalance: wallet[res]?.p2p_balance ? wallet[res]?.p2p_balance : 0,
          avl_balance:
            Math.round(wallet[res]?.available * 10000) / 10000 != 0
              ? Math.round(
                  (wallet[res]?.balance - wallet[res]?.locked) * 10000
                ) / 10000
              : Math.round(
                  (wallet[res]?.balance - wallet[res]?.locked) * 100000000
                ) / 100000000,
          inr_total_balance: getRound(inr_val),
          inr_avail_balance: getRound(inr_available),
          inr_locked_balance: getRound(inr_locked),
        };
      });
    setwalletDetails(final_data);
  }

  useEffect(() => {
    if (activeTab==2) {
      getWallets();
    }
  }, [wallet, coins, paired_curency_price, activeTab]);

  useEffect(() => {
    let coin0 = coin[0] ? coin[0] : currency_type;
    let coin1 = coin[1] ? coin[1] : campare_currency;
    let action = activeTab==0?"open":"order";
    let pair_currency = coin0?.toUpperCase()+"/"+coin1?.toUpperCase();
    N_spotOrder(
      user?.params ? user.params.token : user.token,
      pair_currency,
      "all",
      "all",
      action, 1, 20
    ).then((res) => {
        if (res.status === 200) {
          if(action=="open") {
            dispatch({
              type: SET_USER_ORDER_PENDING,
              data: res.openorders,
              pending_order_loading: false,
            });
          } else {
            dispatch({
              type: SET_USER_ORDER_CLOSE,
              data: res.openorders,
              close_order_loading: false,
            });
          }
          
          
        } else {
          console.log("error to fetch open and close orders: ", res);
        }
      })
      .catch((e) => console.log(e));
  }, [activeTab, props?.match?.params?.id]);

  useEffect(() => {
    setpendingOrder(
      user_order_pending.sort(
        (a, b) => Number(b.order_date) - Number(a.order_date)
      )
    );
    setcloseOrder(
      user_order_close.sort((a, b) => Number(b.order_date) - Number(a.order_date))
    );
  }, [user_order_pending, user_order_close]);

  return (
    <>
      <div className="card mb-1px">
        <div className="card-header py-0 custom_pills">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2 align-items-center">
              <div className="d-flex align-items-center gap-1 text-primary fs-14">
                <div>
                  <HiMiniChartBarSquare className="fs-5" />

                </div>
                <div>
                  Trades
                </div>
              </div>
              <div class="verticle_line"></div>
              <div className="nav nav-pills" id="nav-tab" role="tablist">
                <div
                  className={`nav-item nav-link cursor_pointer  ${activeTab === 0 ? "active" : ""
                    }`}
                  id="nav-home-tab"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                  onClick={() => setActiveTab(0)}
                >
                  Open Orders
                </div>
                <div
                  className={`nav-item nav-link cursor_pointer ${activeTab === 1 ? "active" : ""
                    }`}
                  id="nav-profile-tab"
                  data-toggle="tab"
                  onClick={() => setActiveTab(1)}
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >
                  Completed Orders
                </div>
                <div
                  className={`nav-item nav-link cursor_pointer ${activeTab === 2 ? "active" : ""
                    }`}
                  id="nav-profile-tab"
                  data-toggle="tab"
                  onClick={() => setActiveTab(2)}
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >
                  Assets
                </div>
              </div>
            </div>
            <div>
              {isLoggedIn ? (
                <Link to="/SpotOrders" className="anchor_link fs-12">
                  All Orders
                </Link>
              ) : (
                null
              )}
            </div>
          </div>
        </div>

        <div className="order_height custom_scrollbar">
          {!isLoggedIn ? (
            <div className="tab-content px-2 orders">
              <LoginORSignup />
            </div>
          ) : null}
          {activeTab === 0 && isLoggedIn ? (
            <div className="">
              {/* <div
              className="offset-8 col-4 text-center text-danger cursor"
              style={{ fontSize: "12px" }}
              onClick={() =>
                cancleOrder(token, props.type)
                  .then((d) => {
                    if (d.status === 1) {
                      NotificationManager.success(d.msg);
                      dispatch(getUserOrder(token, props.type));
                      dispatch(getUserBalance(token));
                      dispatch(
                        getOrderBook(coin[0], coin[1], () => {}, props.type)
                      );
                      dispatch(
                        getTradeHist(coin[0], coin[1], () => {}, props.type)
                      );
                    } else {
                      NotificationManager.error(d.msg);
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                  })
              }
            >
              Cancel All
            </div> */}
            </div>
          ) : null}
          {isLoggedIn ? (
            <>
              <div className="tab-content orders">
                <div
                  className={`tab-pane fade ${activeTab === 0 ? "show active" : ""
                    }`}
                  id="open-order"
                >
                  <table className="exchange_tbl-- order-book-trading">
                    <thead className="sticky_thead py-3">
                      <tr>
                        <th>
                          <h6>Spot Pairs</h6>
                        </th>
                        <th>
                          <h6>Trade Type</h6>
                        </th>
                        <th>
                          <h6>Order Type</h6>
                        </th>
                        <th>
                          <h6>Direction</h6>
                        </th>
                        <th>
                          <h6>Filled Value</h6>
                        </th>
                        <th>
                          <h6>Filled Price</h6>
                        </th>
                        <th>
                          <h6>Filled Qty</h6>
                        </th>
                        <th>
                          <h6>Filled Executed</h6>
                        </th>
                        <th>
                          <h6>Trading Fee</h6>
                        </th>
                        <th>
                          <h6>TDS Fee</h6>
                        </th>
                        <th>
                          <h6>Filled Time</h6>
                        </th>
                        <th className="text-center">
                          <h6>Delete</h6>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {pendingOrder && pendingOrder?.length > 0
                        ? pendingOrder.map((d, index) => {
                          return (
                            <OrderRow
                              {...d}
                              key={index}
                              user_id={
                                user?.params ? user.params.token : user.token
                              }
                            />
                          );
                        })
                        : null}
                    </tbody>
                  </table>

                  {pendingOrder?.length === 0 ? (
                    <div className="text-center py-5 text-muted" colspan="3">
                      <div>
                        <PiFolderNotchOpenThin className="fs-4" />
                      </div>
                      <div className="fs-12">No Open Orders !</div>
                    </div>
                  ) : null}
                  {loading ? <Loader /> : null}
                </div>

                <div
                  id="order-history"
                  className={`tab-pane fade ${activeTab === 1 ? "show active" : ""
                    }`}
                >
                  <table className="order-book-trading">
                    <thead className="sticky_thead">
                      <tr>
                        <th>
                          <h6>Spot Pairs</h6>
                        </th>
                        <th>
                          <h6>Trade Type</h6>
                        </th>
                        <th>
                          <h6>Order Type</h6>
                        </th>
                        <th>
                          <h6>Direction</h6>
                        </th>
                        <th>
                          <h6>Filled Value</h6>
                        </th>
                        <th>
                          <h6>Filled Price</h6>
                        </th>
                        <th>
                          <h6>Filled Qty</h6>
                        </th>
                        <th>
                          <h6>Filled Executed</h6>
                        </th>
                        <th>
                          <h6>Trading Fee</h6>
                        </th>
                        <th>
                          <h6>TDS Fee</h6>
                        </th>
                        <th>
                          <h6>Filled Time</h6>
                        </th>
                        <th className="text-center">
                          <h6>Action</h6>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {closeOrder && closeOrder?.length > 0
                        ? closeOrder.map((d, index) => {
                          return (
                            <OrderRow {...d} key={index} />
                          );
                        })
                        : null}
                    </tbody>
                  </table>
                  {closeOrder?.length === 0 ? (
                    <div className="text-center py-5 text-muted" colspan="3">
                      <div>
                        <PiFolderNotchOpenThin className="fs-4" />
                      </div>
                      <div className="fs-12">No Completed Orders !</div>
                    </div>
                  ) : null}
                  {loading ? <Loader /> : null}
                </div>

                <div
                  id="order-history"
                  className={`tab-pane fade ${activeTab === 2 ? "show active" : ""
                    }`}

                    
                >
                  {walletsdata?.length==0?
                  <div className="text-center py-5 text-muted" colspan="3">
                      <div>
                        <PiFolderNotchOpenThin className="fs-4" />
                      </div>
                      <div className="fs-12">No Assets Record Found !</div>
                    </div>:
                    <table className="order-book-trading">
                      <thead className="sticky_thead">
                      <tr>
                        <th>
                          <h6>Coin</h6>
                        </th>
                        <th>
                          <h6>Total</h6>
                        </th>
                        <th>
                          <h6>Available Amount</h6>
                        </th>
                        <th>
                          <h6>In Orders</h6>
                        </th>
                        </tr>
                      </thead>
                      <tbody>
                        {walletsdata?.map((item) => {
                          if(item?.balance>0) {
                            return (
                              <tr key={item?.symbol}>
                                <td>
                                 {item?.symbol}
                                </td>
                                <td>{item?.balance}
                                </td>
                                <td>
                                  {item?.avl_balance}
                                </td>
                                <td>
                                  {item.locked}
                                </td>
                              </tr>
                            );
                          }
                        })}
                      </tbody>
                    </table>}
                  </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}

function OrderRow(props) {
  const [popup, setpopup] = useState(false);
  const [spin, setspin] = useState("");
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });

  const handleDeleteClick = (event) => {
    // Calculate the position based on the click event
    const rect = event.target.getBoundingClientRect();
    const top = rect.bottom + window.scrollY;
    const left = rect.left + window.scrollX;
    // console.log("top", top, "left", left);
    setPopupPosition({ top, left });
    setpopup(true);
  };

  const dispatch = useDispatch();
  let progress_width = 0;
  let back = "rgb(16 129 53 / 10%)";
  const [dis, setdis] = useState(false);
  if (props["total_buy"] === undefined) {
    progress_width = (props.total_executed * 100) / props.volume;
    back = props.type === "buy" ? "rgba(35, 172, 80, 0.4)" : "#81101026";
  } else {
    progress_width = (props.total_executed * 100) / props.volume;
    back = "rgb(16 129 53 / 10%)";
  }

  const deleteOrder = (data) => {
    setspin("spinner-border spinner-border-sm");
    N_cancleOrderById(props.user_id, props.order_id)
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            getUserOrder(
              props.user_id,
              props.currency_type,
              props.compare_currency
            )
          );
          dispatch(getUserBalance(props.user_id));
          setTimeout(() => {
            setspin("");
            setpopup(false);
            setdis(false);
          }, 1000);
          // setLoading(false);
          NotificationManager.success(res.message);
        } else {
          NotificationManager.error(res.message);
        }
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  };

  // function getDateTime(props) {
  //   let ddate = props.order_date;
  //   if (ddate === 0) {
  //     ddate = props.execution_date;
  //   }
  //   return ddate;
  // }

  const handleClose = () => setpopup(false);
  const handleShow = () => setpopup(true);

  return (
    <>
      {popup ? (
        <Modal size="sm" centered show={popup} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="text-center h6">
              Delete Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="text-center">Are you sure you want to delete?</p>
          </Modal.Body>
          <Modal.Footer>
            <div className="row">
              <div className="col-6">
                <Button variant="secondary" block onClick={handleClose}>
                  Cancel
                </Button>
              </div>
              <div className="col-6">
                <Button
                  variant="danger"
                  block
                  onClick={() => {
                    if (!dis) {
                      setdis(true);
                      deleteOrder(props);
                    }
                  }}
                >
                  {dis ? (
                    <span
                      className={`${spin} mx-2`}
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                  Confirm
                </Button> 
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      ) : null}

      <tr>
        <td>
          {props?.pair_name}
        </td>
        <td>Spot</td>
        <td>{props.order_type}</td>
        <td className={` ${props.side === "buy" ? "text-green" : "text-red"}`}>
          {props?.side}
        </td>
        <td>{round(props?.volume * props?.raw_price)}</td>
        <td>{round(props?.raw_price)}</td>
        <td>{round(props?.volume)}</td>
        <td>{round(props?.total_executed)}</td>
        <td>{round(props.fees)}</td>
        <td>{round(props.tds_fees)}</td>
        <td>
        {new Date(Number(props?.order_date)).toLocaleString()}
        </td>

        <td
          className={` ${props?.side === "buy" ? "text-green" : "text-red"
            } text-center`}
        >
          {props?.order_status == 0 ? (
            <div title="Cancel Order" className="" onClick={handleDeleteClick}>
              <FiDelete className="text-danger fs-14" />
            </div>
          ) : props?.order_status == 2 ? (
            <span className="">Cancel</span>
          ) : (
            <span className="">Executed</span>
          )}
        </td>
      </tr>
    </>
  );
}
